import React from "react"
import { graphql, Link } from "gatsby"
import Img from "gatsby-image"

import Layout from "../components/layout/Layout"
import SEO from "../components/global/Seo"

const AboutMeTemplate = ({ data }) => {
  const { markdownRemark } = data
  const { frontmatter, html } = markdownRemark
  const photoFluid = frontmatter.photo.childImageSharp.fluid

  return (
    <Layout breadcrumbs={[{ title: frontmatter.title, url: frontmatter.slug }]}>
      <SEO title={frontmatter.title} />
      <section id="about-us" className="about-us-section section-padding">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-4">
              <Img
                className="mobile-spacing-bottom"
                fluid={photoFluid}
                alt="Banner"
              />
            </div>
            <div className="col-lg-7 offset-lg-1">
              <h2 className="section-subtitle">{frontmatter.title}</h2>
              <div
                className="text-box-padding-xs-right"
                dangerouslySetInnerHTML={{ __html: html }}
              />
              <Link className="button mt-3" to="/kontakt">
                Napisz do mnie
              </Link>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default AboutMeTemplate

export const pageQuery = graphql`
  query($slug: String!) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      html
      frontmatter {
        slug
        title
        description
        photo {
          childImageSharp {
            fluid(maxWidth: 800) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
